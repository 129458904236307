import { Section, Title } from "../../styles/common";

import Container from "../Container";

import {
  TeamWrapper,
  TeamMember,
  MemberImage,
  MemberRole,
  MemberDescription,
} from "./Team.styled";

const Team = () => {
  return (
    <Section>
      <Container>
        <Title>Team</Title>
        <TeamWrapper>
          <TeamMember>
            <MemberImage src="/images/team/dad.jpg" alt="CEO" />
            <MemberRole color="#68CABB">CEO</MemberRole>
            <MemberDescription>
              Senior Software Engineer with 7 years of experience, passionate
              about C#, Rust and Blockchain. Has practice experience in System
              Design, DevOps SQL and NoSql databases.
            </MemberDescription>
          </TeamMember>
          <TeamMember>
            <MemberImage src="/images/team/dev.jpg" alt="Dev" />
            <MemberRole color="#6A518B">Full-Stack Dev</MemberRole>
            <MemberDescription>
              Senior Frontend Developer, skilled in JavaScript(React). Actively
              participating in the community by writing tech articles, teaching
              and mentoring.
            </MemberDescription>
          </TeamMember>
          <TeamMember>
            <MemberImage src="/images/team/artist.jpg" alt="Artist" />
            <MemberRole color="#CA686A">Artist</MemberRole>
            <MemberDescription>
              3d artist/animator for more than a decade now. Indie game
              developer with a couple of games made for mobile/PC/Xbox/Nintendo
              Switch. Talking stuff on my Youtube channel about my journey.
            </MemberDescription>
          </TeamMember>
          <TeamMember>
            <MemberImage
              src="/images/team/marketing.jpg"
              alt="Marketing & Community"
            />
            <MemberRole color="#C1A241">Community & Marketing</MemberRole>
            <MemberDescription>
              Photographer with more than 10 years experience, doing marketing
              and community management. Learning vector graphics. Life was
              divided on 3 parts - before crypto, before NFTs and now.
            </MemberDescription>
          </TeamMember>
        </TeamWrapper>
      </Container>
    </Section>
  );
};

export default Team;
