import { FC, ReactNode } from "react";

import { ContainerWrapper } from "./Container.styled";

interface Props {
  children: ReactNode;
}

const Container: FC<Props> = ({ children }) => (
  <ContainerWrapper>{children}</ContainerWrapper>
);

export default Container;
