import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;

    @media(max-width: 1200px) {
      font-size: 9px;
    }
    
    @media(max-width: 992px) {
      font-size: 8px;
    }

  }

  body {
    font-size: 1.4rem;
    line-height: 2.5rem;
    font-family: "Poppins";

    @media(max-width: 992px) {
      font-size: 1.6rem;
      line-height: 3rem;
    }

    @media(max-width: 768px) {
      font-size: 2rem;
      line-height: 3.5rem;
    }
  }

  html,
  body {
    padding: 0;
    margin: 0;
    color: #d0d0d0;
    background-color: rgb(23,28,28);
    height: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #548c6c;
    }
  }

  * {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
  }

  .app {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  main {
    flex: auto;
  }

  .MuiButton-root {
    color: #d0d0d0!important;
    font-size: 1.4rem!important;
  }
  
  .makeStyles-root-1 .MuiDialogTitle-root {
    background-color: #236253!important;
    color: #d0d0d0;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #fff;
  }
`;

export default GlobalStyle;
