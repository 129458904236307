interface ISocialMedia {
  icon: string;
  pathname: string;
  alt: string;
}

export const socialMedia: ISocialMedia[] = [
  {
    icon: "discord",
    pathname: "https://discord.gg/3297e5AmPm",
    alt: "Discord",
  },
  {
    icon: "twitter",
    pathname: "https://twitter.com/OurSolFamily",
    alt: "Twitter",
  },
];
