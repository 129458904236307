import styled from "styled-components";

export const StyledInput = styled.input<{ width?: number }>`
  width: ${(props) => (props.width ? props.width : 25)}rem;
  height: 4.2rem;
  background: rgba(46, 50, 50, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.12);
  outline: none;
  border-radius: 6px;
  color: #d0d0d0;
  padding: 0 1rem;
  font-size: 1.4rem;
  font-family: "Poppins";
  position: relative;

  @media (max-width: 992px) {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 468px) {
    width: 100%;
  }
`;
