import dayjs from "dayjs";

import { Section } from "../../styles/common";
import { socialMedia } from "../../utils/social";

import Container from "../Container";

import {
  Wrapper,
  Inner,
  Box,
  Col,
  Greetings,
  Copyright,
  MenuWrapper,
  SocialWrapper,
  MenuItem,
} from "./Footer.styled";

const Footer = () => {
  return (
    <Wrapper>
      <Section backgroundColor="#101414">
        <Container>
          <Inner>
            <Box>
              <Col>
                <Greetings>
                  Thank you for being with us. Join our communities and keep
                  track of the latest news.
                </Greetings>
              </Col>
              <Col>
                <MenuWrapper>
                  <MenuItem to="/#marketplaces">Marketplaces</MenuItem>
                  <MenuItem to="/#roadmap">Roadmap</MenuItem>
                  <MenuItem to="/rarity">Rarity</MenuItem>
                  <MenuItem to="/#faq">FAQ</MenuItem>
                </MenuWrapper>
              </Col>
            </Box>
            <Box>
              <Col>
                <Copyright>
                  © {dayjs().year()} solfamily, all rights reserved
                </Copyright>
              </Col>
              <Col>
                <SocialWrapper>
                  {socialMedia.map((item, index) => (
                    <MenuItem
                      key={index}
                      to={{ pathname: item.pathname }}
                      target="__blank"
                    >
                      <img src={`/icons/${item.icon}.svg`} alt={item.alt} />
                    </MenuItem>
                  ))}
                </SocialWrapper>
              </Col>
            </Box>
          </Inner>
        </Container>
      </Section>
    </Wrapper>
  );
};

export default Footer;
