import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Wrapper = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  background: rgba(31, 35, 35, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  height: 10rem;
  z-index: 9999;
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10rem;
`;

export const HeaderCol = styled.div<{ fullOnMobile?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 468px) {
    ${(props) =>
      props.fullOnMobile &&
      css`
        width: 100%;
        margin-right: 4rem;
      `}
  }
`;

export const InputWrapper = styled.div`
  display: inline-block;
  position: relative;

  @media (max-width: 468px) {
    flex: auto;
  }
`;

export const Logo = styled.img`
  width: 5.5rem;
  margin-right: 4rem;
  position: relative;
  z-index: 9999;
`;

export const ElementsWrapper = styled.div`
  position: absolute;
  width: 35rem;
  padding: 2rem;
  background: rgba(31, 35, 35, 1);
  border-radius: 1.8rem;
  left: 0;
  top: 6rem;
  border: 1px solid rgba(255, 255, 255, 0.12);

  @media (max-width: 468px) {
    width: 100%;
    min-width: 30rem;
    right: 0;
  }

  @media (max-width: 360px) {
    left: -8rem;
  }
`;

export const ElementItem = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    color: inherit;
  }
`;

export const ElementImage = styled.img`
  width: 8rem;
  border-radius: 1.2rem;
  margin-right: 2rem;
`;

export const ElementContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
`;

export const ElementContentInner = styled.div``;

export const ElementId = styled.div`
  opacity: 0.5;
`;

export const ElementSummary = styled.div``;

export const AngleRightIcon = styled.img`
  width: 1.4rem;
`;
