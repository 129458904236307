import Accordion from "react-bootstrap/Accordion";
import ReactMarkdown from "react-markdown";

import { Section, Title } from "../../styles/common";

import Container from "../Container";

import { faq } from "./data";
import { Box, Inner, TitleWrapper } from "./FAQ.styled";

const FAQ = () => {
  return (
    <Section id="faqSection">
      <Container>
        <Box>
          <TitleWrapper>
            <Title>FAQ</Title>
          </TitleWrapper>
          <Inner>
            <Accordion>
              {faq.map((item, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>
                    <ReactMarkdown children={item.content} />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Inner>
        </Box>
      </Container>
    </Section>
  );
};

export default FAQ;
