export interface Attribute {
  title: string;
  src: string;
}

export const attributes: Attribute[] = [
  {
    src: "/images/attributes/backgrounds.jpg",
    title: "Background",
  },
  {
    src: "/images/attributes/heads.jpg",
    title: "Face",
  },
  {
    src: "/images/attributes/mouth.jpg",
    title: "Mouth",
  },
  {
    src: "/images/attributes/shirts.jpg",
    title: "Body",
  },
  {
    src: "/images/attributes/hair.jpg",
    title: "Heads - Hairs",
  },
  {
    src: "/images/attributes/headphones.jpg",
    title: "Heads - Headphones",
  },
  {
    src: "/images/attributes/hats.jpg",
    title: "Heads - Hats",
  },
  {
    src: "/images/attributes/accessories.jpg",
    title: "Chains + Earrings",
  },
  {
    src: "/images/attributes/glasses.jpg",
    title: "Glasses",
  },
  {
    src: "/images/attributes/beards.jpg",
    title: "Beards",
  },
];
