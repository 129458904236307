import Container from "../Container";

import { Section } from "../../styles/common";
import {
    Wrapper,
    StyledImage,
    CountdownTitle,
    StyledCountdown,
    ImagesWrapper,
} from "./Mint.styled";


const mintEnabled = process.env.REACT_APP_VINSY_MINT_ENABLE === "true"

const Mint = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
            <CountdownTitle>Holders of SolDads, SolMoms and Uncle Vinsy.. We've got some news for you!</CountdownTitle><br/>
            <StyledCountdown>
                If you are an OG Soldad or Solmom holder, you are elgible to a whitelist mint.<br/> If you have a "SOLFAMILY FIRST SETTLERS" role you are automatically Whitelisted 
                and will<br/> receive one free SOLKID, aside from transaction fees, for when our SolKids are ready to come home.
            </StyledCountdown>
            <br/>
            <StyledCountdown>
              Join us in <a href="https://discord.gg/3297e5AmPm">discord</a> to learn more
            </StyledCountdown>
            {mintEnabled}
            {/* <h2>Price: 0 SOL + gas, Supply: 340</h2> */}
          <ImagesWrapper>
            <StyledImage src="/images/kids.gif" alt="Uncle Vinsy" />
          </ImagesWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Mint;
