import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  width: 34rem;
  max-width: 100%;
  border-radius: 50%;
  margin-bottom: 3rem;
  margin-top: 8rem;

  @media (max-width: 768px) {
    margin: 2rem 0;
  }

  &:first-child {
    width: 40rem;
    margin-right: 4rem;

    @media (max-width: 768px) {
      width: 26rem;
      margin: 4rem 0 4rem 0;
    }
  }

  &:last-child {
    width: 28rem;
    margin-left: 4rem;

    @media (max-width: 768px) {
      width: 26rem;
      margin: 4rem 0 4rem 0;
    }
  }
`;



export const CountdownTitle = styled.h2`
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledCountdown = styled.h2`
  text-align: center;
  line-height: 30px;
  a {
    color: #548c6c;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const CommonButton = css`
  padding: 2rem 6rem !important;
  border-radius: 1.8rem !important;
  border: 0 !important;
  outline: 0 !important;
  background-color: #236253 !important;
  color: #d0d0d0 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
`;

export const GenericButton = styled.button`
  ${CommonButton};
`;