import styled from "styled-components";

export const Inner = styled.div`
  background: rgba(31, 35, 35, 1);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 3rem;
  padding: 4rem;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Title = styled.h3`
  font-weight: 400;
  font-size: 3.6rem;
  margin: 0 0 4rem 0;
  text-align: center;
`;

export const MarketplacesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: grayscale(1);
  margin: 0 -4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const MarketplaceItem = styled.img<{ width: number }>`
  max-width: 100%;
  margin: 0 4rem;
  width: ${(props) => props.width}rem;

  @media (max-width: 768px) {
    margin: 0 0 6rem 0;
  }
`;
