import styled, { css } from "styled-components";

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }
`;

export const BenefitItem = styled.div`
  width: calc((100% / 3) - 4rem);
  margin-bottom: 4rem;
  margin-right: 4rem;

  @media (max-width: 768px) {
    width: 50%;
    margin-right: 0;
  }

  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const BenefitTitle = styled.div`
  color: #79d29f;
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

export const BenefitContent = styled.div``;

export const RoadmapWrapper = styled.div``;

export const RoadmapItem = styled.div`
  display: flex;

  @media (max-width: 992px) {
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

export const RoadmapBox = styled.div<{
  empty?: boolean;
  finished?: boolean;
  last?: boolean;
}>`
  flex: 5;
  max-width: 52rem;
  min-width: 52rem;

  @media (max-width: 1200px) {
    max-width: 48.5rem;
    min-width: 48.5rem;
  }

  @media (max-width: 992px) {
    display: none;
  }

  ${({ empty, finished, last }) =>
    !empty &&
    css`
      position: relative;
      padding: 2rem;
      border-radius: 1.8rem;

      ${finished
        ? css`
            background-color: rgba(84, 140, 108, 0.12);
            border: 1px solid rgba(84, 140, 108, 0.6);
          `
        : css`
            background-color: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.12);
          `}

      @media (max-width: 992px) {
        display: flex;

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 4rem;
          bottom: -4.2rem;
          left: 50%;
          background-color: rgba(84, 140, 108, 0.6);

          ${!finished &&
          css`
            background-color: rgba(255, 255, 255, 0.12);
          `}

          ${last &&
          css`
            display: none;
          `}
        }
      }

      @media (max-width: 468px) {
        min-width: 100%;
        max-width: 100%;
      }
    `}
`;

export const Timeline = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const Body = styled.div``;

export const BodySection = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Heading = styled.div`
  text-transform: uppercase;
  color: #79d29f;
  margin-bottom: 1.25rem;
`;

export const Content = styled.div``;

export const Inner = styled.div`
  background: rgba(31, 35, 35, 1);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 3rem;
  padding: 4rem;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const RoadmapHorizontalLine = styled.div<{ empty?: boolean }>`
  height: 1px;
  width: 100%;
  flex: 1;
  align-self: center;

  ${(props) =>
    !props.empty &&
    css`
      background-color: rgba(84, 140, 108, 0.6);
    `}

  @media (max-width: 992px) {
    display: none;
  }
`;

export const RoadmapVerticalLine = styled.div`
  width: 1px;
  position: relative;
  top: 0;
  bottom: 0;
  background-color: rgba(84, 140, 108, 0.6);
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 992px) {
    display: none;
  }
`;
