import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

export const getDads = (state: AppState) => state.nft.dads;

export const getDadsSelector = createSelector(getDads, (dads) => dads);

export const getMoms = (state: AppState) => state.nft.moms;

export const getMomsSelector = createSelector(getMoms, (moms) => moms);
