import { Section, Title } from "../../styles/common";

import Container from "../Container";

import {
  Content,
} from "./Roadmap.styled";

const Roadmap = () => {
  return (
    <Section id="roadmapSection">
      <Container>
        <Title>Benefits & Roadmap</Title>
        <Content>SolFamily NFT project. <br/><br/>

        The kids will be 2.5 SOL <br/><br/>
        
        It will be a HIDDEN REVEAL and once they mint out fully we will unveil the kids!!!!! <br/><br/>

To have an ALPHA ROLE one must have the following:<br/><br/>

1 MOM<br/>
1 DAD<br/>
3 KIDS<br/><br/>
MUST PAY FEES FOR ROYALTYS ON SECONDARY ON NFT PURCHASES<br/><br/>

You can have multiple wallets.<br/><br/>

THE ALPHA ROLE will give you access to monthly SOLANA distributions for all secondary sales of DADS, MOMS, KIDS. no  strings attached!
<br/><br/>



💎 BREAKDOWN💎 <br/><br/>

Total royalty on secondary 30% plus 2% me fee so 32% total.<br/>

Out of the 30% that holders control, 

we will distribute 25% of royalty to be shared to holder of alpha roles.<br/>

5% stays with community wallet for management fees and costs.<br/><br/>


ie:<br/>

AN NFT AT THE COST OF 15 SOL, sells for 19.8 after fees

seller gets 15 sol<br/>

(25%) royalty wallet gets 3.75 solana to be even shared to all holders active in snapshots <br/>


Community wallet 5% 0.75 solana<br/>

Say we sell in 30 days 40 nfts @ 15 solana,

payouts will be shared between the qualified wallets. <br/>

(25%) royalty wallet gets 150 solana to distribute<br/>

(5%)community wallet gets 30 solana<br/><br/>

As sales increase so do the shared profits for holders<br/><br/>



"SOLFAMILY FIRST SETTLERS" roles will get one FREE KID. you would have to purchase 2 more to complete your "alpha role"<br/><br/>

During the mint we will have 10 CUSTOM 1/1 GOLDEN KIDS that is part of the GOLDEN TICKET CAMPAIGN, you simply need to be lucky to mint one of them, and you will win 20 SOLANA!!!!!!<br/><br/>

For the biggest whale to purchase during MINT they too will win 20 SOLANA<br/><br/>

Make sure to get your whitelist role!!!</Content>
        {/*
        <BenefitsWrapper>
          {benefits.map((item, index) => (
            <BenefitItem key={index}>
              <BenefitTitle>{item.title}</BenefitTitle>
              <BenefitContent>{item.content}</BenefitContent>
            </BenefitItem>
          ))}
        </BenefitsWrapper>
        <RoadmapWrapper>
          <RoadmapItem>
            <RoadmapBox finished>
              <Timeline>September 2021</Timeline>
              <Body>
                <BodySection>
                  <Heading>Concept</Heading>
                  <Content>
                    Create concept of SolDads with further usage and interesting
                    events.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Implementation</Heading>
                  <Content>Generate 5750 unique 3D SolDads.</Content>
                </BodySection>
                <BodySection>
                  <Heading>Socials</Heading>
                  <Content>Create website, twitter and discord.</Content>
                </BodySection>
              </Body>
            </RoadmapBox>
            <RoadmapHorizontalLine />
            <RoadmapVerticalLine>2021</RoadmapVerticalLine>
            <RoadmapHorizontalLine empty />
            <RoadmapBox empty />
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapBox empty />
            <RoadmapHorizontalLine empty />
            <RoadmapVerticalLine />
            <RoadmapHorizontalLine />
            <RoadmapBox finished>
              <Timeline>October 2021</Timeline>
              <Body>
                <BodySection>
                  <Heading>Giveaways</Heading>
                  <Content>
                    We want to allocate 200 SolDads NFT for collaboration with
                    other projects and giveaways.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Pre-sale</Heading>
                  <Content>
                    We want to allocate 1500 NFTs for our early Discord members.
                    All conditions of participation will be announced soon.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Public minting</Heading>
                  <Content>
                    Remaining SolDads will be released on our website in launch
                    date. There will be 4050 SolDads available for the mint.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Listing</Heading>
                  <Content>
                    We will try to involve all the marketplaces you know. As
                    soon as we have the results after the negotiations, we will
                    immediately announce them.
                  </Content>
                </BodySection>
              </Body>
            </RoadmapBox>
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapBox finished>
              <Timeline>November 2021</Timeline>
              <Body>
                <BodySection>
                  <Heading>Ranking system</Heading>
                  <Content>
                    Implement ranking system for SOL Dads based on time and
                    price history parameters.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Custom candy machine</Heading>
                  <Content>
                    We will create custom candy machine smart contract will full
                    on-chain sale logic.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Secret drop</Heading>
                  <Content>
                    Special collection of 1150 Toolkits for SOL Dad holders
                    based on weights and tiers lottery. Toolkits will be used to
                    build houses.
                  </Content>
                </BodySection>
              </Body>
            </RoadmapBox>
            <RoadmapHorizontalLine />
            <RoadmapVerticalLine />
            <RoadmapHorizontalLine empty />
            <RoadmapBox empty />
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapBox empty />
            <RoadmapHorizontalLine empty />
            <RoadmapVerticalLine />
            <RoadmapHorizontalLine />
            <RoadmapBox>
              <Timeline>December 2021</Timeline>
              <Body>
                <BodySection>
                  <Heading>SolMoms</Heading>
                  <Content>
                    There will be another 5750 collection of cool SolMoms each
                    SolDad needs SolMom!
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>SolKids</Heading>
                  <Content>
                    SolDads and SolMoms holders are a very important community
                    to us and we treat them as early adopters. We are preparing
                    a collection of SolKids. Each SolKid will be a result of
                    love between SolDad and SolMom.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Family houses collection</Heading>
                  <Content>
                    A special colleciton of 5750 NFTs of family house parts. 5
                    parts can be used to build a house for your family. Don't
                    forget about a Toolkit from Toolkit collection that you
                    should have to build the house.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Attributes marketplace/shop</Heading>
                  <Content>
                    A special marketplace where family holders will be able to
                    buy/sell attributes for their NFTs.
                  </Content>
                </BodySection>
              </Body>
            </RoadmapBox>
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapBox>
              <Timeline>January 2022</Timeline>
              <Body>
                <BodySection>
                  <Heading>Secret 10 000 items collection</Heading>
                  <Content>
                    A special colleciton of 10 000 NFTs which will be dropped
                    for Family DAO members. These NFTs will be used in one of
                    our metaverses.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Integration with Solana-Ethereum bridge</Heading>
                  <Content>
                    All our NFT collections will be available on Ethereum
                    network.
                  </Content>
                </BodySection>
                <BodySection>
                  <Heading>Family multiverse token</Heading>
                  <Content>
                    A special token based on Solana network. It will be used as
                    a bridge token across all family metaverses.
                  </Content>
                </BodySection>
              </Body>
            </RoadmapBox>
            <RoadmapHorizontalLine />
            <RoadmapVerticalLine>2022</RoadmapVerticalLine>
            <RoadmapHorizontalLine empty />
            <RoadmapBox empty />
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapBox empty />
            <RoadmapHorizontalLine empty />
            <RoadmapVerticalLine />
            <RoadmapHorizontalLine />
            <RoadmapBox last>
              <Timeline>February 2022</Timeline>
              <Body>
                <BodySection>
                  <Heading>TBA</Heading>
                  <Content>Coming soon.</Content>
                </BodySection>
              </Body>
            </RoadmapBox>
          </RoadmapItem>
        </RoadmapWrapper>
      */}
      </Container>
    </Section>
  );
};

export default Roadmap;
