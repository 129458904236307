import { Section, Title } from "../../styles/common";

import Container from "../Container";

import { attributes } from "./data";
import {
  AttributesWrapper,
  AttributeItem,
  AttributeImage,
  AttributeTitle,

} from "./Attributes.styled";

const Attributes = () => {
  return (
    <Section id="attributesSection" backgroundColor="#548C6C">
      <Container>
        <Title>Attributes</Title>
        <AttributesWrapper>
          {attributes.map((item, index) => (
            <AttributeItem key={index}>
              <AttributeImage src={item.src} alt={item.title} />
              <AttributeTitle>{item.title}</AttributeTitle>
            </AttributeItem>
          ))}

        </AttributesWrapper>
      </Container>
    </Section>
  );
};

export default Attributes;
