import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const TeamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -4rem;

  @media (max-width: 992px) {
    margin: 0 -2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const TeamMember = styled.div`
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  width: calc((100% / 4) - 8rem);

  @media (max-width: 992px) {
    margin: 0 2rem;
    width: calc((100% / 4) - 4rem);
  }

  @media (max-width: 768px) {
    margin: 0;
    width: 50%;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const MemberImage = styled.img`
  max-width: 100%;
  width: 24rem;
  margin-bottom: 2rem;
  border-radius: 50%;

  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const MemberRole = styled.div<{ color: string }>`
  font-size: 1.8rem;
  margin-bottom: 2rem;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

export const MemberDescription = styled.div`
  margin-bottom: 2rem;
  text-align: left;
`;

export const MemberSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  align-items: flex-end;
`;

export const SocialLink = styled(Link)``;

export const SocialIcon = styled.img`
  margin: 0 1rem;
`;
