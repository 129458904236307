import styled, { css } from "styled-components";

export const Section = styled.div<{ backgroundColor?: string }>`
  padding: 8rem 0;

  @media (max-width: 768px) {
    padding: 4rem 0;
  }

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export const Title = styled.h2<{ noMargin?: boolean }>`
  font-size: 5.4rem;
  font-weight: 400;
  text-align: center;
  ${(props) =>
    !props.noMargin &&
    css`
      margin: 0 0 4rem 0;
    `}

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
  }
`;
