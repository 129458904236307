import { Link } from "react-router-dom";
import styled from "styled-components";

export const AttributesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4rem;

  @media (max-width: 468px) {
    margin: 0 -2rem;
  }
`;

export const AttributeItem = styled.div`
  width: calc((100% / 5) - 8rem);
  margin-bottom: 4rem;
  margin: 0 4rem 4rem 4rem;

  @media (max-width: 992px) {
    width: calc((100% / 4) - 8rem);
  }

  @media (max-width: 768px) {
    width: calc((100% / 3) - 8rem);
  }

  @media (max-width: 468px) {
    width: calc((100% / 2) - 4rem);
    margin: 0 2rem 2rem 2rem;
  }
`;

export const AttributeImage = styled.img`
  width: 100%;
  border-radius: 1.6rem;
  margin-bottom: 1rem;
`;

export const AttributeTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
`;

export const ViewAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ViewAllLink = styled(Link)`
  font-size: 1.8rem;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;

  &:hover {
    color: #151515;
  }
`;
