import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getDadsSelector } from "../../store/nft/selectors";
import { getMomsSelector } from "../../store/nft/selectors";
import { NFT } from "../../store/nft/types";

import Container from "../Container";
import Menu from "../Menu";
import Input from "../Input";

import RarityPreview from "./RarityPreview";
import { Wrapper, Inner, HeaderCol, InputWrapper, Logo } from "./Header.styled";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";


const Header = () => {
  const nftsDads = useSelector(getDadsSelector);
  const nftsMoms = useSelector(getMomsSelector);

  const getNfts = () => {
    return collection === 'Dad' ? nftsDads : nftsMoms;
  }

  const [query, setQuery] = useState("");
  const [collection, setCollection] = useState("Dad");
  const [displayElements, setDisplayElements] = useState<NFT[]>([]);

  useEffect(() => {
    if (query) {

      const newElements = getNfts().filter(
        (el) => el.name.toString().toLowerCase() === query.toLowerCase()
      );
      setDisplayElements(newElements);
    } else {
      setDisplayElements([]);
    }
  }, [query, getNfts()]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleNavigate = () => {
    setQuery("");
  };

  const toggleRarityPreview = () => {
    setQuery("");
  };

  const updateCollection = (e: any) => {
    setCollection(e.target.value);
  };

  return (
    <Wrapper>
      <Container>
        <Inner>
          <HeaderCol fullOnMobile>
            <Link to="/">
              <Logo src="/favicon.ico" alt="Logo" />
            </Link>
            <RadioGroup name="value" value={collection} onChange={updateCollection}>
            <FormControlLabel
                label={"Dad"}
                key={"Dad"}
                value={"Dad"}
                control={<Radio color="primary" />}
            />
            <FormControlLabel
                label={"Mom"}
                key={"Mom"}
                value={"Mom"}
                control={<Radio color="primary" />}
            />
            </RadioGroup>
            <InputWrapper>
              <Input
                value={query}
                onChange={handleChange}
                placeholder={"Enter "+ collection + "'s ID for details..."}
              />
              {displayElements.length > 0 && (
                <RarityPreview
                    collection={collection}
                  displayElements={displayElements}
                  handleNavigate={handleNavigate}
                />
              )}
            </InputWrapper>
          </HeaderCol>
          <HeaderCol>
            <Menu
              showCloseIcon={!!query}
              toggleRarityPreview={toggleRarityPreview}
            />
          </HeaderCol>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default Header;
