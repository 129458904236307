import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-top: 1px solid #232a2a;
`;

export const Inner = styled.div``;

export const Box = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Col = styled.div``;

export const Greetings = styled.div`
  max-width: 22.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Copyright = styled.div`
  opacity: 0.5;
  text-transform: uppercase;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 4rem;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MenuItem = styled(Link)`
  display: inline-block;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 2rem;

    @media (max-width: 768px) {
      margin: 0 0 2rem 0;
    }
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    ${MenuItem} {
      margin: 0 1rem;
    }
  }
`;
