export const CONNECT_ACCOUNT = "CONNECT_ACCOUNT";
export const CONNECT_ACCOUNT_FULFILLED = "CONNECT_ACCOUNT_FULFILLED";
export const CONNECT_ACCOUNT_REJECTED = "CONNECT_ACCOUNT_REJECTED";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_FULFILLED = "CREATE_ACCOUNT_FULFILLED";
export const CREATE_ACCOUNT_REJECTED = "CREATE_ACCOUNT_REJECTED";

export const GET_SECRET_MESSAGE = "GET_SECRET_MESSAGE";
export const GET_SECRET_MESSAGE_FULFILLED = "GET_SECRET_MESSAGE_FULFILLED";
export const GET_SECRET_MESSAGE_REJECTED = "GET_SECRET_MESSAGE_REJECTED";

export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_FULFILLED = "VERIFY_ACCOUNT_FULFILLED";
export const VERIFY_ACCOUNT_REJECTED = "VERIFY_ACCOUNT_REJECTED";

export const ATTACH_DISCORD = "ATTACH_DISCORD";
export const ATTACH_DISCORD_FULFILLED = "ATTACH_DISCORD_FULFILLED";
export const ATTACH_DISCORD_REJECTED = "ATTACH_DISCORD_REJECTED";

export const DISCONNECT_DISCORD = "DISCONNECT_DISCORD";
export const DISCONNECT_DISCORD_FULFILLED = "DISCONNECT_DISCORD_FULFILLED";
export const DISCONNECT_DISCORD_REJECTED = "DISCONNECT_DISCORD_REJECTED";

export const DISCONNECT_ACCOUNT = "DISCONNECT_ACCOUNT";

export const CONNECT_LEDGER = "CONNECT_LEDGER";
