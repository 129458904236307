import React, { FC, ReactNode } from "react";
import { Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Mint from "./components/Mint";
import Marketplaces from "./components/Marketplaces";
import Roadmap from "./components/Roadmap";
import Attributes from "./components/Attributes";
import FAQ from "./components/FAQ";
import Team from "./components/Team";
import Footer from "./components/Footer";

import GlobalStyle from "./styles/GlobalStyle";
import useScrollTo from "./utils/hooks/useScrollTo";

const AttributesList = React.lazy(() => import("./components/AttributesList"));
//const Ranking = React.lazy(() => import("./components/Ranking"));
const RaritySelector = React.lazy(() => import("./components/RaritySelector"));
const Rarity = React.lazy(() => import("./components/Rarity"));
const RarityDetails = React.lazy(() => import("./components/RarityDetails"));

const Fallback: FC<{ children: ReactNode }> = ({ children }) => (
  <div
    style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {children}
  </div>
);

const App = () => {
  useScrollTo();

  return (
    <div className="app">
      <GlobalStyle />
      <Header />
      <main style={{ marginTop: "10rem" }}>
        <React.Suspense fallback={<Fallback>Loading...</Fallback>}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <>
                  <Mint />
                  <Hero />
                  <Marketplaces />
                  <Roadmap />
                  <Attributes />
                  <FAQ />
                  <Team />
                </>
              )}
            ></Route>
            <Route path="/attributes" component={AttributesList}></Route>
            <Route
              path="/rarity/dads/:id"
              component={() => <RarityDetails type="dad" />}
            ></Route>
            <Route
              path="/rarity/dads"
              component={() => <Rarity type="dad" />}
            ></Route>
            <Route
              path="/rarity/moms/:id"
              component={() => <RarityDetails type="mom" />}
            ></Route>
            <Route
              path="/rarity/moms"
              component={() => <Rarity type="mom" />}
            ></Route>
            <Route path="/rarity" component={RaritySelector}></Route>
            </Switch>
        </React.Suspense>
      </main>
      <Footer />
    </div>
  );
};

export default App;
