import { combineReducers } from "redux";

import familyzer from "./familyzer/reducer";
import nft from "./nft/reducer";
import ranking from "./ranking/reducer";

const rootReducer = combineReducers({
  familyzer,
  nft,
  ranking,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
