import dads from "../../dads.json";
import moms from "../../moms.json";

import { NFT, NFTState } from "./types";

const initialState: NFTState = {
  dads: dads as NFT[],
  moms: moms as NFT[],
};

const reducer = (state = initialState): NFTState => {
  return {
    ...state,
  };
};

export default reducer;
