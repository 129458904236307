interface IFAQ {
  title: string;
  content: string;
}

export const faq: IFAQ[] = [
  {
    title: "What happened to this project?",
    content: `After the SolMoms mint the project went quiet and the original team was unreachable for some time.
      They re-engaged the discord community and handed over the project to a group of volunteers to bring new 
      life to the project.`,
  },
  {
    title: "What about the Roadmap?",
    content: `We plan to work with the community to rework the roadmap. This project has so much potential and we want the community to be a part of it.`,
  },
  {
    title: "Who is Uncle Vinsy?",
    content:
        'Uncle Vinsy will be your marker as a SolSettler.  Our intent is to find a way to reward anyone who has stuck with us from the beginning.',
  },
  {
    title: "How can I buy?",
    content: `To buy a SolDad or SolMom, visit one of the marketplaces they are listed on. 
      
Links can be found in the "Marketplaces" section above, just click on the logo and you will be redirected.`,
  },
  {
    title: "How can I participate in an airdrop?",
    content:
      "To be able to participate in all future airdrops, join our Discord server, wait for the announcements and giveaways and fulfill the requirements.",
  },
  {
    title: "How can I check my NFT rarity?",
    content:
      'To check the rarity of your SolDad or SolMom, click on the "Rarity" menu link on this website and provide an id of your NFT (0-5749).',
  },
  {
    title: "What about SOL Kids?",
    content:
      "They are also planned to be dropped for SOL Dads and SOL Moms holders, further news will be announced in the Discord.",
  },
];
