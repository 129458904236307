import {
  FETCH_RANKING,
  FETCH_RANKING_FULFILLED,
  FETCH_RANKING_REJECTED,
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_FULFILLED,
  FETCH_LEADERBOARD_REJECTED,
} from "./actionTypes";
import { RankingActions, RankingState } from "./types";

const initialState: RankingState = {
  loading: false,
  total: 0,
  data: [],
  error: "",
  leaderboard: {
    loading: false,
    data: [],
    error: "",
  },
};

const reducer = (
  state = initialState,
  action: RankingActions
): RankingState => {
  switch (action.type) {
    case FETCH_RANKING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_RANKING_FULFILLED:
      return {
        ...state,
        loading: false,
        total: action.payload.total,
        data: action.payload.entities.map((item) => {
          return {
            ...item,
            rank: action.payload.rank,
          };
        }),
        error: "",
      };
    case FETCH_RANKING_REJECTED:
      return {
        ...state,
        loading: false,
        total: 0,
        data: [],
        error: action.payload,
      };
    case FETCH_LEADERBOARD:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          loading: true,
          error: "",
        },
      };
    case FETCH_LEADERBOARD_FULFILLED:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          loading: false,
          data: action.payload.entities,
          error: "",
        },
      };
    case FETCH_LEADERBOARD_REJECTED:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
