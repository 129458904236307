import styled, { css } from "styled-components";

export const Wrapper = styled.div``;

export const FamilyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 6rem -4rem 0 -4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const FamilyItem = styled.div`
  margin: 0 4rem;
  max-width: calc(100% / 3);

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

export const FamilyImage = styled.img<{ grey?: boolean }>`
  max-width: 100%;
  width: 32rem;
  margin: 0 auto 2rem auto;
  border-radius: 50%;
  display: flex;

  ${(props) =>
    props.grey &&
    css`
      filter: grayscale(1);
    `};

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }

  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: 400;
  text-align: center;
`;

export const ItemTitle = styled.h4`
  margin: 0;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: 400;
`;

export const ItemInfo = styled.div``;

export const ItemHint = styled.div`
  text-transform: uppercase;
  opacity: 0.75;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ItemStatus = styled.div<{ status: "soldOut" | "comingSoon" }>`
  text-transform: uppercase;

  ${(props) =>
    props.status === "soldOut"
      ? css`
          color: #ff4242;
        `
      : props.status === "comingSoon"
      ? css`
          color: #b3adeb;
        `
      : ""}
`;
