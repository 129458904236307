import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  margin-right: 4rem;
  flex: 1;
`;

export const Inner = styled.div`
  flex: 2;

  .accordion {
    border-radius: 1.8rem;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }

  .accordion-item {
    background: rgba(31, 35, 35, 1);
    border: 0;
  }

  .accordion-button {
    background: rgba(31, 35, 35, 1);
    color: #d0d0d0;
    padding: 2rem;
    font-size: 1.8rem;

    &:hover {
      background-color: #323939;
    }

    &::after {
      background-image: url(/icons/angle-down.svg);
    }
  }

  .accordion-body {
    padding: 2rem;
    font-size: 1.6rem;
  }
`;
