import React, { ChangeEvent } from "react";

import { StyledInput } from "./Input.styled";

interface Props {
  width?: number;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = (props) => (
  <StyledInput
    value={props.value}
    onChange={props.onChange}
    placeholder={props.placeholder}
    width={props.width}
  />
);

export default Input;
