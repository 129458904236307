import { FC, useState } from "react";

import { socialMedia } from "../../utils/social";

import {
  MenuWrapper,
  MenuItem,
  Dropdown,
  DropdownText,
  TriangleDown,
  DropdownInner,
  DropdownItem,
  Hamburger,
  Line,
  MobileMenu,
  SocialWrapper,
  SocialSlice,
} from "./Menu.styled";

interface Props {
  showCloseIcon: boolean;
  toggleRarityPreview: VoidFunction;
}

const Menu: FC<Props> = ({ showCloseIcon, toggleRarityPreview }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMenu((prevValue) => !prevValue);
  };

  return (
    <>
      <MenuWrapper allowMobile>
        <MenuItem to="/#marketplaces">Marketplaces</MenuItem>
        <MenuItem to="/#roadmap">Roadmap</MenuItem>
        <MenuItem to="/rarity">Rarity</MenuItem>
        <MenuItem to="/#faq">FAQ</MenuItem>
        <Dropdown>
          <DropdownText>Socials</DropdownText>
          <TriangleDown src="/icons/triangle-down.svg" alt="Triangle" />
          <DropdownInner>
            {socialMedia.map((item, index) => (
              <DropdownItem
                key={index}
                to={{ pathname: item.pathname }}
                target="__blank"
              >
                <img
                  src={`/icons/${item.icon}.svg`}
                  alt={item.alt}
                  style={{ marginRight: "2rem" }}
                />
                {item.alt}
              </DropdownItem>
            ))}
          </DropdownInner>
        </Dropdown>
      </MenuWrapper>
      {/* Mobile menu */}
      <Hamburger
        onClick={showCloseIcon ? toggleRarityPreview : toggleMobileMenu}
      >
        <Line
          style={{
            transform:
              showMenu || showCloseIcon ? "rotate(45deg)" : "rotate(0)",
          }}
        />
        <Line
          style={{
            opacity: showMenu || showCloseIcon ? "0" : "1",
          }}
        />
        <Line
          style={{
            transform:
              showMenu || showCloseIcon ? "rotate(-45deg)" : "rotate(0)",
          }}
        />
      </Hamburger>
      {showMenu && (
        <MobileMenu>
          <MenuItem to="/#marketplaces">Marketplaces</MenuItem>
          <MenuItem to="/#roadmap">Roadmap</MenuItem>
          <MenuItem to="/rarity">Rarity</MenuItem>
          <MenuItem to="/#faq">FAQ</MenuItem>
          <SocialWrapper>
            <SocialSlice>
              {socialMedia.slice(0, 4).map((item, index) => (
                <MenuItem
                  key={index}
                  to={{ pathname: item.pathname }}
                  target="__blank"
                >
                  <img src={`/icons/${item.icon}.svg`} alt={item.alt} />
                </MenuItem>
              ))}
            </SocialSlice>
            <SocialSlice>
              {socialMedia.slice(4, socialMedia.length).map((item, index) => (
                <MenuItem
                  key={index}
                  to={{ pathname: item.pathname }}
                  target="__blank"
                >
                  <img src={`/icons/${item.icon}.svg`} alt={item.alt} />
                </MenuItem>
              ))}
            </SocialSlice>
          </SocialWrapper>
        </MobileMenu>
      )}
    </>
  );
};

export default Menu;
