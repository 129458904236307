import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const MenuWrapper = styled.div<{ allowMobile?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 992px) {
    ${(props) =>
      props.allowMobile &&
      css`
        display: none;
      `}
  }
`;

const MenuItemStyles = css`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

export const MenuItem = styled(Link)`
  ${MenuItemStyles};
  height: 100%;

  &:hover {
    color: #548c6c;
  }
`;

export const DropdownText = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    color: #fff;
  }
`;

export const TriangleDown = styled.img`
  margin-left: 1rem;
`;

export const DropdownInner = styled.div`
  ${MenuItemStyles};
  display: none;
  position: absolute;
  top: 8rem;
  right: 0;
  padding: 2rem 6rem 2rem 2rem;
  background: rgba(31, 35, 35, 1);
  border-radius: 1.8rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
`;

export const Dropdown = styled.div`
  ${MenuItemStyles};
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    ${DropdownInner} {
      display: block;
    }
  }
`;

export const DropdownItem = styled(Link)`
  display: flex;

  &:hover {
    color: #fff;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Hamburger = styled.div`
  width: 3.5rem;
  height: 3rem;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 9999;
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 1rem;
  transition: all 0.2s linear;
  background-color: #d0d0d0;
  transform-origin: -1px;
`;

export const MobileMenu = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(31, 35, 35, 1);
  z-index: 9999;
  font-size: 2rem;
  flex-direction: column;
  display: none;
  margin: 2rem;
  border-radius: 1.8rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  top: 10rem;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${MenuItem} {
    margin: 0;
    padding: 3rem;

    @media (max-width: 992px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${MenuItem} {
    padding: 3rem 2rem;
  }
`;

export const SocialSlice = styled.div`
  display: flex;
  align-items: center;
  margin: auto;

  &:last-child {
    ${MenuItem} {
      padding-top: 0;
    }
  }
`;
