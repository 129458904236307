import {
  CONNECT_ACCOUNT,
  CONNECT_ACCOUNT_FULFILLED,
  CONNECT_ACCOUNT_REJECTED,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FULFILLED,
  CREATE_ACCOUNT_REJECTED,
  GET_SECRET_MESSAGE,
  GET_SECRET_MESSAGE_FULFILLED,
  GET_SECRET_MESSAGE_REJECTED,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_FULFILLED,
  VERIFY_ACCOUNT_REJECTED,
  ATTACH_DISCORD,
  ATTACH_DISCORD_FULFILLED,
  ATTACH_DISCORD_REJECTED,
  DISCONNECT_DISCORD,
  DISCONNECT_DISCORD_FULFILLED,
  DISCONNECT_DISCORD_REJECTED,
  DISCONNECT_ACCOUNT,
  CONNECT_LEDGER,
} from "./actionTypes";
import { FamilyzerActions, FamilyzerState } from "./types";

const initialState: FamilyzerState = {
  ledger: false,
  connect: {
    loading: false,
    success: false,
    error: {
      responseCode: "",
      message: "",
    },
    verified: false,
    isDiscordConnected: false,
  },
  create: {
    loading: false,
    success: false,
    error: {
      responseCode: "",
      message: "",
    },
  },
  getSecretMessage: {
    loading: false,
    message: "",
    error: {
      responseCode: "",
      message: "",
    },
  },
  verifyAccount: {
    loading: false,
    success: false,
    signature: "",
    error: {
      responseCode: "",
      message: "",
    },
  },
  attachDiscord: {
    loading: false,
    success: false,
    error: {
      responseCode: "",
      message: "",
    },
  },
  disconnectDiscord: {
    loading: false,
    success: false,
    error: {
      responseCode: "",
      message: "",
    },
  },
};

const reducer = (
  state = initialState,
  action: FamilyzerActions
): FamilyzerState => {
  switch (action.type) {
    /* Connect account */
    case CONNECT_ACCOUNT:
      return {
        ...state,
        connect: {
          ...state.connect,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case CONNECT_ACCOUNT_FULFILLED:
      return {
        ...state,
        connect: {
          ...state.connect,
          loading: false,
          success: true,
          verified: action.payload.verified,
          isDiscordConnected: action.payload.isDiscordConnected,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case CONNECT_ACCOUNT_REJECTED:
      return {
        ...state,
        connect: {
          ...state.connect,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    /* Create account */
    case CREATE_ACCOUNT:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case CREATE_ACCOUNT_FULFILLED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case CREATE_ACCOUNT_REJECTED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    /* Get secret message */
    case GET_SECRET_MESSAGE:
      return {
        ...state,
        getSecretMessage: {
          ...state.getSecretMessage,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case GET_SECRET_MESSAGE_FULFILLED:
      return {
        ...state,
        getSecretMessage: {
          ...state.getSecretMessage,
          loading: false,
          message: action.payload,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case GET_SECRET_MESSAGE_REJECTED:
      return {
        ...state,
        getSecretMessage: {
          ...state.getSecretMessage,
          loading: false,
          message: "",
          error: action.payload,
        },
      };
    /* Verify account */
    case VERIFY_ACCOUNT:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case VERIFY_ACCOUNT_FULFILLED:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: false,
          success: true,
          signature: action.payload,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case VERIFY_ACCOUNT_REJECTED:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: false,
          success: false,
          signature: "",
          error: action.payload,
        },
      };
    /* Attach discord */
    case ATTACH_DISCORD:
      return {
        ...state,
        attachDiscord: {
          ...state.attachDiscord,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case ATTACH_DISCORD_FULFILLED:
      return {
        ...state,
        attachDiscord: {
          ...state.attachDiscord,
          loading: false,
          success: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
        getSecretMessage: {
          ...state.getSecretMessage,
          message: "",
        },
      };
    case ATTACH_DISCORD_REJECTED:
      return {
        ...state,
        attachDiscord: {
          ...state.attachDiscord,
          loading: false,
          success: false,
          error: action.payload,
        },
        getSecretMessage: {
          ...state.getSecretMessage,
          message: "",
        },
      };
    /* Disconnect discord */
    case DISCONNECT_DISCORD:
      return {
        ...state,
        disconnectDiscord: {
          ...state.disconnectDiscord,
          loading: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
      };
    case DISCONNECT_DISCORD_FULFILLED:
      return {
        ...state,
        disconnectDiscord: {
          ...state.disconnectDiscord,
          loading: false,
          success: true,
          error: {
            responseCode: "",
            message: "",
          },
        },
        connect: {
          ...state.connect,
          isDiscordConnected: false,
        },
        getSecretMessage: {
          ...state.getSecretMessage,
          message: "",
        },
      };
    case DISCONNECT_DISCORD_REJECTED:
      return {
        ...state,
        disconnectDiscord: {
          ...state.disconnectDiscord,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    /* Connect ledger */
    case CONNECT_LEDGER:
      return {
        ...state,
        ledger: !state.ledger,
      };
    /* Disconnect account */
    case DISCONNECT_ACCOUNT:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
