import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollTo = () => {
  const location = useLocation();

  const handleScrollTo = (section: string) => {
    const element = document.getElementById(`${section}Section`);
    if (element) {
      const header = document.getElementsByTagName("header")[0];
      if (header) {
        window.scrollTo({
          top:
            element.getBoundingClientRect().top +
            window.scrollY -
            header.getBoundingClientRect().height,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const element = location.hash.split("#")[1];

    setTimeout(() => {
      handleScrollTo(element);
    }, 200);
  }, [location.hash]);
};

export default useScrollTo;
