import { FC } from "react";

import { NFT } from "../../store/nft/types";

import {
  ElementsWrapper,
  ElementItem,
  ElementImage,
  ElementContent,
  ElementContentInner,
  ElementId,
  ElementSummary,
  AngleRightIcon,
} from "./Header.styled";

interface Props {
  collection: String;
  displayElements: NFT[];
  handleNavigate: VoidFunction;
}

const RarityPreview: FC<Props> = ({ collection, displayElements, handleNavigate }) => {
  return (
    <ElementsWrapper>
      {displayElements.map((element) => {
        return (
          <ElementItem
            to={`/rarity/${collection.toLowerCase()}s/${element.name}`}
            key={element.name}
            onClick={handleNavigate}
          >
            <ElementImage src={element.image} />
            <ElementContent>
              <ElementContentInner>
                <ElementId>Sol{collection} #{element.name}</ElementId>
                <ElementSummary>Rank: {element.rank}</ElementSummary>
                <ElementSummary>
                  Rarity: {element.totalRarity.toFixed(2)}%
                </ElementSummary>
              </ElementContentInner>
              <AngleRightIcon src="/icons/angle-right.svg" />
            </ElementContent>
          </ElementItem>
        );
      })}
    </ElementsWrapper>
  );
};

export default RarityPreview;
