import Container from "../Container";

import { Section } from "../../styles/common";

import {marketplacesDads, marketplacesMoms} from "./data";
import {
  Inner,
  Title,
  MarketplacesWrapper,
  MarketplaceItem,
} from "./Marketplaces.styled";

const Marketplaces = () => {
  return (
    <Section id="marketplacesSection">
      <Container>
        <Inner>
          <Title>Marketplaces for SolDads</Title>
          <MarketplacesWrapper>
            {marketplacesDads.map((marketplace, index) => (
              <a
                key={index}
                href={marketplace.link}
                target="__blank"
                style={{ display: "block" }}
              >
                <MarketplaceItem
                  src={marketplace.path}
                  alt={marketplace.alt}
                  width={marketplace.width}
                />
              </a>
            ))}
          </MarketplacesWrapper>
        </Inner>
      </Container>

      <Container>
        <Inner>
          <Title>Marketplaces for SolMoms</Title>
          <MarketplacesWrapper>
            {marketplacesMoms.map((marketplace, index) => (
                <a
                    key={index}
                    href={marketplace.link}
                    target="__blank"
                    style={{ display: "block" }}
                >
                  <MarketplaceItem
                      src={marketplace.path}
                      alt={marketplace.alt}
                      width={marketplace.width}
                  />
                </a>
            ))}
          </MarketplacesWrapper>
        </Inner>
      </Container>
    </Section>
  );
};

export default Marketplaces;
