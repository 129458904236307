import Container from "../Container";

import { Section, Title } from "../../styles/common";

import {
  Wrapper,
  FamilyWrapper,
  FamilyItem,
  FamilyImage,
  ItemTitle,
  ItemInfo,
  ItemHint,
  ItemStatus,
} from "./Hero.styled";

const Hero = () => {
  return (
    <Wrapper>
      <Section>
        <Container>
          <Title>Meet our SolFamily</Title>
          <FamilyWrapper>
            <FamilyItem>
              <FamilyImage src="/images/hero-dad.webp" alt="Dad" />
              <ItemTitle>SolDads</ItemTitle>
              <ItemInfo>Mint: 1.75 SOL</ItemInfo>
              <ItemInfo>Supply: 5750</ItemInfo>
              <ItemStatus status="soldOut">Sold Out</ItemStatus>
            </FamilyItem>
            <FamilyItem>
              <FamilyImage src="/images/hero-mom.webp" alt="Mom" />
              <ItemTitle>SolMoms</ItemTitle>
              <ItemInfo>Mint: 1 SOL</ItemInfo>
              <ItemInfo>Supply: TBA</ItemInfo>
              <ItemStatus status="soldOut">Mint Complete</ItemStatus>
            </FamilyItem>
            <FamilyItem>
              <FamilyImage src="/images/vinsy.webp" alt="Vinsy" />
              <ItemTitle>Vinsy</ItemTitle>
              <ItemHint></ItemHint>
              <ItemInfo>Mint: 0 SOL + gas</ItemInfo>
              <ItemInfo>Supply: 340</ItemInfo>
              <ItemStatus status="soldOut">Mint Complete</ItemStatus>
            </FamilyItem>
            <FamilyItem>
              <FamilyImage src="/images/New-hero-kid.webp" alt="Kid" />
              <ItemTitle>SolKids</ItemTitle>
              <ItemInfo>Mint: TBA</ItemInfo>
              <ItemInfo>Supply: TBA</ItemInfo>
              <ItemHint>Free kid for Settlers</ItemHint>
              <ItemStatus status="comingSoon">Coming soon</ItemStatus>
            </FamilyItem>
          </FamilyWrapper>
        </Container>
      </Section>
    </Wrapper>
  );
};

export default Hero;
