export interface Marketplace {
  path: string;
  link: string;
  alt: string;
  width: number;
}

export const marketplacesDads: Marketplace[] = [
  {
    path: "/images/marketplaces/solanart.webp",
    link: "https://solanart.io/collections/soldads",
    alt: "solanart",
    width: 6,
  },
  {
    path: "/images/marketplaces/aart.svg",
    link: "https://alpha.art/collection/soldads",
    alt: "alphaart",
    width: 12,
  },
  {
    path: "/images/marketplaces/solsea.svg",
    link: "https://solsea.io/collection/6167090a30756da3da8465e0",
    alt: "solsea",
    width: 18,
  },
  {
    path: "/images/marketplaces/magiceden.svg",
    link: "https://magiceden.io/marketplace/soldads",
    alt: "magiceden",
    width: 18,
  },
];
export const marketplacesMoms: Marketplace[] = [
  {
    path: "/images/marketplaces/solanart.webp",
    link: "https://solanart.io/collections/solmoms",
    alt: "solanart",
    width: 6,
  },
  {
    path: "/images/marketplaces/solsea.svg",
    link: "https://solsea.io/collection/61b8ceb7ad3360626f97a67a",
    alt: "solsea",
    width: 18,
  },
];
